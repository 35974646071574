import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 页面样式重置
import './assets/css/reset.css'

// import Vant from 'vant';
// import 'vant/lib/index.css';
// 屏幕自适应px to rem
import './utils/rem'
import 'bootstrap/dist/css/bootstrap.min.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
Vue.prototype.$axios = axios;
// 地图插件
import AMap from 'vue-amap';

  // 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'f56737132458f86f0b21a6a758596d7b',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation']
});

// animate.css
import animated from 'animate.css/animate.css'
// 轮播插件
// import Swiper from 'swiper' 
//也可以用require
//var Vue = require('vue')
//var VueAwesomeSwiper = require('vue-awesome-swiper')
// import Vant from 'vant';
// import 'vant/lib/index.css';

// 滚动插件
import { HappyScroll } from 'vue-happy-scroll'
//自定义组件名
Vue.component('happy-scroll', HappyScroll)
// 引入css
import 'vue-happy-scroll/docs/happy-scroll.css'
Vue.use(ElementUI).use(AMap).use(animated);



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
