import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		// 主屏首页
		path: '/',
		name: 'Index',
		component: () => import('../views/index.vue')
	},
	{
		// 关于我们
		path: '/about',
		name: 'About',
		component: () => import('../views/about.vue')
	},
	{
		// 公司产品
		path: '/product',
		name: 'Product',
		component: () => import('../views/product.vue')
	},
	{
		// 解决方案
		path: '/way',
		name: 'Way',
		component: () => import('../views/way.vue')
	},
	{
		// 加入我们
		path: '/join',
		name: 'Join',
		component: () => import('../views/join.vue')
	},
	{
		// 联系我们
		path: '/connect',
		name: 'Connect',
		component: () => import('../views/connect.vue')
	}
]

const router = new VueRouter({
	routes
})

export default router
