<template>
	<div id="app">
		<!-- 顶部导航栏 -->
		<div :class="{'nav':isNav,'switch':isSwitch}" id="nav-all">
			<!--导航栏-->
			<nav class="navbar" id="navbar">
				<div class="container-fluid navbar-default navbar-fixed-top" :class="{'nav-bg':isNavBg,'nav-bg-act':isNavBg_act}">
					<!-- Brand and toggle get grouped for better mobile display -->
					<div class="navbar-header">
						<!--LOGO-->
						<a class="navbar-brand" href="#">
							<img alt="LOGO" class="logo hidden-xs hidden-sm" src="@/assets/img/logo.png" @click="toIndex(0)" v-if="isNav">
							<img alt="LOGO" class="logo hidden-xs hidden-sm" src="@/assets/img/logo-act.png" @click="toIndex(0)" v-else>
						</a>
						<!--switch-bar-->
						<button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
							data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
							<span class="sr-only">Toggle navigation</span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
						</button>
						<!--小尺寸时显示-->
						<!--<a class="navbar-brand" href="#">首页</a>-->
					</div>

					<!-- Collect the nav links, forms, and other content for toggling -->
					<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
						<!--tabs-->
						<ul class="nav navbar-nav" id="nav">
							<li>
								<a :class="a_actid==0?'a-act':''" @click="toIndex(0)">首页 <span class="sr-only">(current)</span></a>
							</li>
							<li>
								<a :class="a_actid==1?'a-act':''" @click="toAbout(1)">关于我们</a>
							</li>
							<li>
								<a :class="a_actid==2?'a-act':''" @click="toProduct(2)">公司产品</a>
							</li>
							<li>
								<a :class="a_actid==3?'a-act':''" @click="toWay(3)">解决方案</a>
							</li>
							<li>
								<a :class="a_actid==4?'a-act':''" @click="toJoin(4)">加入我们</a>
							</li>
							<li>
								<a :class="a_actid==5?'a-act':''" @click="toConnect(5)">联系我们</a>
							</li>
						</ul>
						<ul class="nav navbar-nav navbar-right">
							<!--搜索-->
							<form class="navbar-form navbar-left search">
								<div class="form-group">
									<input type="text" class="form-control" placeholder="搜索...">
									<span class="glyphicon glyphicon-search"></span>
								</div>
							</form>
						</ul>
					</div>
					<!-- /.navbar-collapse -->
				</div>
				<!-- /.container-fluid -->
			</nav>
		</div>
		
		<!-- 中间部分 -->
		<router-view></router-view>
		
		
		<!-- 底部导航 -->
		<footer>
			<div class="more-nav">
				<el-row type="flex" class="row-bg" justify="space-between">
					<el-col :span="10">
						<div class="grid-content bg-purple">
							<h1>中正数服（杭州）数据有限公司</h1>
							<ul>
								<li>Address: 浙江省杭州市滨江区滨文路15号丰利中心1幢</li>
								<li>Phone: 138-3222-9992</li>
								<li>Email: tour@tockdata.com</li>
								<li>Website: www.tockdata.com</li>
							</ul>
						</div>
					</el-col>
					<el-col :span="10">
						<div class="grid-content bg-purple">
							<img src="@/assets/img/ewm.jpg">
						</div>
					</el-col>
				</el-row>
				<!-- 下划线 -->
				<div class="hr"></div>
				<!-- 版权信息 -->
				<div class="banquan">
					<span>浙ICP备20022787号</span>
					<span>©2018 - 2022 tockdata.com 版权所有</span>
				</div>
			</div>
		</footer>
	</div>
</template>
<script>
	export default {
		name: "Index",
		data() {
			return {
				// 用于切换导航栏文字颜色
				isNav:true,
				isSwitch:false,
				// 导航选中的a标签
				a_actid:0,
				// a标签选中时的颜色
				fontcolor:"#0000FE !important",
				// clientHeight距离顶部的高度
				clientHeight:0,
				// 选中时的背景颜色改变
				// 黑色
				isNavBg:false,
				// 白色
				isNavBg_act:false
			};
		},
		methods: {
			toIndex(id) {
				this.$router.push("/");
				this.a_actid=id;
			},
			toAbout(id) {
				this.$router.push("/about");
				this.a_actid=id;
			},
			toProduct(id) {
				this.$router.push("/product");
				this.a_actid=id;
			},
			toWay(id) {
				this.$router.push("/way");
				this.a_actid=id;
			},
			toJoin(id) {
				this.$router.push("/join");
				this.a_actid=id;
			},
			toConnect(id) {
				this.$router.push("/connect");
				this.a_actid=id;
			},
			handleScroll() {
			    //窗口滚要做的操作写这里
				 console.log('滚动高度', window.pageYOffset);
				 console.log(this.isNavBg)
				 console.log(this.a_actid)
				 // clientHeight距离顶部的高度
				 this.clientHeight = window.pageYOffset;
				console.log(this.clientHeight)
				
				
				
			 },
		},
		
		watch:{
			// 监听clientHeight的变化
			clientHeight:function(){
				if(this.a_actid==0){
					this.isNavBg_act=false;
					if(this.clientHeight>50){
						this.isNavBg=true;
					}else{
						this.isNavBg=false;
					}
				}else{
					this.isNavBg_act=true;
					this.isNavBg=false;
				}
			},
			// 监听a_actid的变化
			a_actid:function(){
				if(this.a_actid==0){
					this.isNavBg_act=false;
					this.isNav=true;
					this.isSwitch=false;
					if(this.clientHeight>50){
						this.isNavBg=true;
					}else{
						this.isNavBg=false;
					}
				}else{
					this.isNav=false;
					this.isSwitch=true;
					this.isNavBg_act=true;
					this.isNavBg=false;
				}
			}
		},
		mounted() {
			 //监听页面滚动事件
			window.addEventListener("scroll", this.handleScroll);
			console.log(window.location.href.slice(window.location.href.indexOf('#')+1));
			// 当前路由url
			var url=window.location.href.slice(window.location.href.indexOf('#')+1);
			if(url=='/'){
				this.a_actid=0;
			}else if(url=='/about'){
				this.a_actid=1;
			}else if(url=='/product'){
				this.a_actid=2;
			}else if(url=='/way'){
				this.a_actid=3;
			}else if(url=='/join'){
				this.a_actid=4;
			}else if(url=='/connect'){
				this.a_actid=5;
			}
			
			// 切换导航文字颜色
			if(url!=='/'){
				this.isSwitch=true;
				this.isNav=false;
			}
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		}
		
	};
</script>
<style lang="scss">
	.navbar-nav>li>a {
		padding-top: 4px;
		padding-bottom: 0;
		height: 28px;
	}

	.navbar {
		min-height: 20px;
	}
	
	.left {
		float: left;
	}

	.clearfix::after {
		display: block;
		clear: both;
		content: '';
		font-size: 0px;
		height: 0px;
		visibility: hidden;
	}

	.clearfix {
		display: block;
		*zoom: 1;
	}
	
	// 整个nav
	#nav-all{
		z-index: 10000 !important;
	}
	
	
	// 导航栏容器
	.container-fluid{
		max-width: 100vw !important;
		width: 100vw !important;
		max-height: 100vh !important;
		height: 86px !important;
		margin: 0 !important;
		padding: 0 260px !important;
	}
	
	// 导航黑色背景
	.nav-bg{
		background: #000 !important;
		/* Safari and Chrome */
		-webkit-animation:animatedBackground 5s;
		animation: animatedBackground 5s;
	}
	// 导航白色背景
	.nav-bg-act{
		background: #fff !important;
	}


	/* 首页 关于我们...... */
	#nav{
		padding: 0;
		li{
			box-sizing: border-box;
		}
	}
	
	// a标签点击选中时的样式
	.a-act{
		padding-top: 26px !important;
		border-top: 4px solid #194FA8 !important;
	}
	
	.navbar-nav {
		li a{
			box-sizing: border-box;
			padding: 6px 30px;
			color: #333 !important;
			font-size: 20px;
		}
	}
	.navbar-nav:first-child{
		margin-left: 80px !important;
	}
	.navbar-nav:last-child{
		margin-left: 10px !important;
	}
	.navbar-right{
		margin: 0 !important;
	}

	/*导航条*/
	.nav{
		z-index: 10000 !important;
	}
	.navbar {
		width: 100%;
		max-height: 80px;
		border: none;
		z-index: 1000 !important;
		/* background: black; */
		/*logo大小*/

		.container-fluid {
			background: inherit;
			box-shadow: none;
			max-width: 1401px;
			max-height: 31px;
			z-index: 1000;
		}

		.navbar-brand {
			height: 31px;
		    padding: 25px 0px;
			margin-right: 118px;
			margin: 0;
			.logo {
				width: 211px;
				height: 31px;
			}
		}


		/* 首页 关于我们...... */
		.navbar-nav {
			padding: 25px 0px;
			li a {
				box-sizing: border-box;
				padding: 30px;
				color: #fff !important;
				font-size: 20px;
			}

			li a:hover {
				cursor: pointer;
				color: #0077C0 !important;
			}
		}


		/*搜索*/
		.search {
			margin: 0;

			.form-group {
				position: relative;

				input {
					height: 31px;
					border-radius: 17.5px;
					background: inherit;
					border: 1px solid #fff;
				}

				input::-webkit-input-placeholder {
					color: #fff;
				}

				input::-moz-input-placeholder {
					color: #fff;
				}

				input::-ms-input-placeholder {
					color: #fff;
				}
			}

			span {
				position: absolute;
				top: 10px;
				right: 10px;
				color: #fff;
			}
		}
	}
	
	// 切换致其他页面时的导航文字颜色
	/*导航条*/
	.switch{
		padding: 25px 0px 10px 0px;
		.navbar {
			width: 100%;
			max-height: 80px;
			border: none;
			z-index: 1000 !important;
			/* background: black; */
			/*logo大小*/
			margin: 0;
			.container-fluid {
				background: inherit;
				box-shadow: none;
				max-width: 1401px;
				max-height: 31px;
				z-index: 1000;
			}
		
			.navbar-brand {
				height: 31px;
			    padding: 25px 0px;
				margin-right: 118px;
				margin: 0;
				.logo {
					width: 211px;
					height: 31px;
				}
			}
		
		
			/* 首页 关于我们...... */
			.navbar-nav {
				li a {
					box-sizing: border-box;
					padding: 30px;
					color: #333 !important;
					font-size: 20px;
				}
		
				li a:hover {
					color: #0077C0 !important;
					cursor: pointer;
				}
			}
		
		
			/*搜索*/
			.search {
				margin: 0;
		
				.form-group {
					position: relative;
		
					input {
						height: 31px;
						border-radius: 17.5px;
						background: inherit;
						border: 1px solid #333;
					}
		
					input::-webkit-input-placeholder {
						color: #999;
					}
		
					input::-moz-input-placeholder {
						color: #999;
					}
		
					input::-ms-input-placeholder {
						color: #999;
					}
				}
		
				span {
					position: absolute;
					top: 10px;
					right: 10px;
					color: #999;
				}
			}
		}
	}
	


	// 底部导航
	footer {
		background: black;

		.more-nav {
			margin: 0 auto;
			padding: 95px 240px 0px 245px;
			.row-bg{
				max-width: 1438px;
				padding: 0;
				h1{
					height: 32px;
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #FFFFFF;
					margin-bottom: 39px;
				}
				ul{
					height: 123px;
					li{
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 36px;
					}
				}
				img{
					width: 160px;
					height: 160px;
				}
				.el-col:last-child{
					width: 160px;
					height: 160px;
				}
			}

			.hr {
				margin-top: 50px;
				width: 1440px;
				height: 1px;
				background: #FFFFFF;
				opacity: 0.15;
			}

			.banquan {
				padding: 22px;
				text-align: center;
				span{
					display: inline-block;
				}
				span:first-child{
					margin-right: 27px;
				}
			}
		}
	}
</style>

